import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import Container from "../components/Container";
import PageContent from "../components/PageContent";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy policy" description="We take your privacy very seriously. Here is an overview of our privacy policy." />
    <Container>
      <PageContent>
        <h1>Information that is gathered from visitors</h1>
        <p>
          As is common with other websites, cookie log files are stored on the web server saving details such as the visitor’s IP
          address, browser type, referring page and time of visit. Cookies may be used to remember visitor preferences when
          interacting with this website. Where registration is required, the visitor’s email and a username will be stored on the
          server. By using this website you consent to our use of cookie tracking to provide a better experience.
        </p>
        <h2>Visitor Options</h2>
        <p>
          If you have subscribed to one of our services, you may unsubscribe by following the instructions which are included in
          e-mail that you receive.
        </p>
        <p>
          You may be able to block cookies via your browser settings but this may prevent you from accessing certain features of
          this website.
        </p>
        <h2>Cookies</h2>
        <p>
          A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is
          added and the cookie helps to analyze web traffic or let you know when you visit a particular website. Cookies allow web
          applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and
          dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyze data about webpage traffic, so that we can improve our website and tailor it
          to the needs of our customers and visitors. We only use this information for analytical purposes and then the data is
          removed from the system. Cookies allow us to provide you with a better user experience, by enabling us to monitor which
          pages you find useful and which you do not. A cookie does not give us access to your computer or any personal
          information about you, other than the data you choose to share with us. You can choose to enable or disable cookies.
          Most web browsers automatically accept cookies, but you can usually modify your browser setting to disable cookies if
          you prefer. Disabling cookies, however, may prevent you from taking full advantage of the website.
        </p>
        <h2>Google Ads</h2>
        <p>Google, as a third party vendor, uses cookies to serve ads.</p>
        <p>
          Google’s use of the DART cookie enables it to serve ads to visitors based on their visit to sites they visit on the
          Internet.
        </p>
        <p>
          Website visitors may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.
        </p>
        <h2>Google Analytics</h2>
        <p>
          Google Analytics, as a third party vendor, uses cookies to collect data from website visitors including but not limited
          to time on site, device information, browser information, etc. By using this site you consent to our use of this data.
        </p>
        <p>
          How Google uses cookie data when you use our site:{" "}
          <a href="https://policies.google.com/technologies/partner-sites">policies.google.com/technologies/partner-sites</a>
        </p>
      </PageContent>
    </Container>
  </Layout>
);

export default PrivacyPolicy;
